import { useEffect, useState } from 'react';
import styles from './InterbankCallbackPage.module.css';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Api from '../../api/Api';
import { ReactComponent as CheckMark } from '../../assets/images/check.svg';
import Button from '../Button';
import ErrorPopup from '../ErrorPopup';
import FatalError from '../FatalError';
import LoadingIndicator from '../LoadingIndicator';
import StepContentHeader from '../steps/StepContentHeader';

const InterbankCallbackPage = () => {
  const navigate = useNavigate();
  const [dataLoading, setDataLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [api, setApi] = useState(null);
  const [searchParams] = useSearchParams();
  const [requestId, setRequestId] = useState(null);
  const [code, setCode] = useState(null);
  const [dataExchanged, setDataExchanged] = useState(false);
  const [isFatalCrash, setIsFatalCrash] = useState(false);
  const [missingParams, setMissingParams] = useState(false);

  useEffect(() => {
    setMissingParams(false);
    const codeParam = searchParams.get('code');
    const stateParam = searchParams.get('state');
    if (!codeParam || !stateParam) {
      setMissingParams(true);
    }
    setRequestId(searchParams.get('state'));
    setCode(searchParams.get('code'));
  }, [searchParams]);

  useEffect(() => {
    if (requestId) {
      const api = new Api(requestId);
      setApi(api);
    }
  }, [requestId]);

  useEffect(() => {
    const triggerCallbackOnServer = async () => {
      setDataExchanged(false);
      setDataLoading(true);

      const { data, error } = await api.performInterbankCallback(
        searchParams.get('code'),
      );

      setDataLoading(false);

      if (data) {
        setDataExchanged(true);

        if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
          navigate(`/${requestId}`);
        } else {
          window.location.replace(data.url);
        }
      } else if (error) {
        setLoadingError(error);
        setIsFatalCrash(true);
      }
    };

    if (api && code) {
      triggerCallbackOnServer();
    }
  }, [api, code, searchParams]);

  const handleErrorCollapse = () => {
    setLoadingError(null);
    setIsFatalCrash(false);
    navigate(`/${requestId}`);
  };
  return (
    <div className={styles.wrapper}>
      <StepContentHeader>Получение данных из МСИ</StepContentHeader>
      <ErrorPopup
        errorText={loadingError}
        triggerClose={() => setLoadingError(null)}
        showContactButton
      ></ErrorPopup>
      {dataLoading ? <LoadingIndicator /> : ''}
      {dataExchanged ? (
        <div className={styles.dataFetched}>
          <CheckMark />
          <div>Данные получены</div>
        </div>
      ) : (
        false
      )}
      {!dataLoading && missingParams && !isFatalCrash ? (
        <FatalError
          customText={
            <div className={styles.missingParam}>
              Не хватает данных для продолжения. Возможно, Вы не предоставили
              доступ к данным на странице аутентификации МСИ
            </div>
          }
          customButton={
            <Button filled={false} onClick={handleErrorCollapse}>
              На страницу заявки
            </Button>
          }
        />
      ) : (
        ''
      )}
      {isFatalCrash ? (
        <FatalError
          customText={
            'Возникла ошибка при обмене данных с МСИ, которая не позволяет продолжить работу'
          }
          customButton={
            <Button filled={false} onClick={handleErrorCollapse}>
              На страницу заявки
            </Button>
          }
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default InterbankCallbackPage;
