import { useContext } from 'react';
import { ClientDataContext } from '../context/client-data';
import { UserInputDataContext } from '../context/user-input-data';
import styles from './FormInputSet.module.css';
import Input from './Input';
import Select from './Select';

const FormInputSet = ({ inputGroups, failedInputs }) => {
  const { userInputData, setUserInputData } = useContext(UserInputDataContext);
  const { clientData } = useContext(ClientDataContext);

  const handleInputChange = ({ name, value }) => {
    setUserInputData((prev) => {
      let updatedData = { ...prev };
      updatedData[name] = value;
      return updatedData;
    });
  };

  const resetError = (name) => {
    if (failedInputs?.[name]) {
      delete failedInputs[name];
    }
  };

  const generateInput = (input, fieldName, sameAsClientData) => {
    return (
      <Input
        key={fieldName}
        value={
          (sameAsClientData
            ? clientData?.interbankData?.[
                fieldName.includes('Address')
                  ? fieldName.replace('living', 'reg')
                  : fieldName
              ]
            : userInputData[fieldName]) || ''
        }
        label={input.label}
        name={fieldName}
        required={input.required || false}
        onChangeCallback={handleInputChange}
        error={failedInputs?.[fieldName]?.message}
        type={input.type || 'text'}
        maxLength={input.maxLength}
        resetError={() => resetError(fieldName)}
        disabled={sameAsClientData}
      />
    );
  };

  const generateSelect = (select, fieldName) => {
    return (
      <Select
        key={fieldName}
        optionTypes={select.optionTypes}
        value={userInputData !== null ? userInputData[fieldName] || '' : ''}
        label={select.label}
        name={fieldName}
        required={select.required || false}
        isMultiSelect={select.isMultiSelect}
        onChangeCallback={handleInputChange}
        error={failedInputs?.[fieldName]?.message}
        resetError={() => resetError(fieldName)}
      />
    );
  };

  return (
    <div>
      {inputGroups.map((group, groupIndex) => {
        return (
          <div className={styles.inputsContainer} key={groupIndex}>
            {group.groupChildren}
            {!group.useConditionalRender ||
            (group.useConditionalRender && group.renderCondition)
              ? Object.keys(group.inputs).map((fieldName) => {
                  const input = group.inputs[fieldName];
                  if (input.type !== 'select')
                    return generateInput(
                      input,
                      fieldName,
                      group.sameAsClientData,
                    );
                  else return generateSelect(input, fieldName);
                })
              : ''}
          </div>
        );
      })}
    </div>
  );
};

export default FormInputSet;
