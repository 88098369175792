import styles from './RadioInput.module.css';

const RadioInput = ({
  value,
  label,
  disabled,
  name,
  onChangeCallback,
  options = [
    { value: true, name: 'Да' },
    { value: false, name: 'Нет' },
  ],
  required,
}) => {
  const handleChange = (newValue) => {
    if (onChangeCallback && name) {
      onChangeCallback({ name: name, value: newValue });
    }
  };

  const handleKeyUp = (event, value) => {
    if (event.key === 'Enter') return !disabled && handleChange(value);
  };

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <span className={styles.label}>
        <span className={styles.labelText}>{label}</span>
        {required ? <span className={styles.requiredIndicator}>*</span> : ''}
      </span>

      <div className={styles.options}>
        {options.map((option, index) => {
          return (
            <div
              className={`${styles.option} ${value === option.value ? styles.selected : ''}`}
              key={index}
              onClick={() => !disabled && handleChange(option.value)}
              onKeyUp={(e) => handleKeyUp(e, option.value)}
              tabIndex={!disabled ? 0 : null}
            >
              <div className={styles.circleOuter}>
                <div className={styles.circleInner}></div>
              </div>
              <div className={styles.name}>{option.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioInput;
