import { useContext } from 'react';
import { ClientDataContext } from '../context/client-data';
import styles from './DocumentsReview.module.css';
import DocumentFileItem from './steps/documents/DocumentFileItem';

const DocumentsReview = () => {
  const { clientData } = useContext(ClientDataContext);
  return (
    <div className={styles.container}>
      {clientData?.documentFiles?.map((doc, index) => (
        <DocumentFileItem
          fileName={doc.name}
          filePath={doc.path}
          accepted={doc.isSigned}
          disabled={true}
          actionDownload={true}
          key={index}
        />
      ))}
    </div>
  );
};

export default DocumentsReview;
