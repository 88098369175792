import { useNavigate } from 'react-router-dom';
import Button from './Button';
import StepContentHeader from './steps/StepContentHeader';
import styles from './UntouchedRequestBlock.module.css';

const UntouchedRequestBlock = ({ requestId }) => {
  const navigate = useNavigate();

  const redirectToInterbankPage = () => {
    if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
      navigate(`/oauth2/callback?code=123&state=${requestId}`);
    } else {
      const interbankRedirectUri = process.env.REACT_APP_INTERBANK_REDIRECT_URI;
      const interbankPageUrl = process.env.REACT_APP_INTERBANK_PAGE_URL;
      const clientId = process.env.REACT_APP_INTERBANK_CLIENT_ID;
      const scope = process.env.REACT_APP_INTERBANK_SCOPE;
      const redirectUrl = `${interbankPageUrl}?client_id=${clientId}&state=${requestId}&response_type=code&authentication=online_otp&redirect_uri=${interbankRedirectUri}&scope=${scope}`;
      window.location.replace(redirectUrl);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <StepContentHeader>Уважаемый клиент!</StepContentHeader>
        <p className={styles.text}>
          Вам необходимо пройти процедуру аутентификации в МСИ.
        </p>
        <p className={styles.text}>
          После этого Вы будете перенаправлены обратно, на страницу Лайт Лизинг.
        </p>
        <div className={styles.buttonContainer}>
          <Button filled={false} onClick={redirectToInterbankPage}>
            Перейти в МСИ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UntouchedRequestBlock;
