import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../context/api';
import { ClientDataContext } from '../../../context/client-data';
import { InternalStepStateContext } from '../../../context/internal-step-state-context';
import StepContentHeader from '../StepContentHeader';
import Button from './../../Button';
import Checkbox from './../../Checkbox';
import ConfirmationCodeForm from './../../ConfirmationCodeForm';
import LinkButton from './../../LinkButton';
import LoadingIndicator from './../../LoadingIndicator';
import DocumentFileItem from './DocumentFileItem';
import DocumentSigningHint from './DocumentSigningHint';
import styles from './DocumentsStep.module.css';

const DocumentsStep = ({ onStepComplete, onStepBack }) => {
  const { clientData } = useContext(ClientDataContext);
  const { generateDocumentFiles, signRequest, dataLoading } =
    useContext(ApiContext);
  const [allDocsChecked, setAllDocsChecked] = useState(false);
  const [showAcceptDocsError, setShowAcceptDocsError] = useState(false);
  const { internalStepState, setInternalStepState } = useContext(
    InternalStepStateContext,
  );
  const confirmationCode = internalStepState.confirmationCode;
  const documentsReviewed = internalStepState.documentsReviewed;
  const codeLength = 6;
  const mainPhoneNumber =
    clientData?.interbankData?.phoneSmsVerification ||
    clientData?.interbankData?.sessionInfoAuthPhone;

  const setDocumentsReviewed = (value) => {
    setInternalStepState({ ...internalStepState, documentsReviewed: value });
  };

  const requiredDocs = internalStepState.requiredDocs;

  const setRequiredDocs = (value) => {
    setInternalStepState({ ...internalStepState, requiredDocs: value });
  };

  useEffect(() => {
    if (!documentsReviewed) generateDocumentFiles();
  }, []);

  useEffect(() => {
    const newRequiredDocs = clientData?.documentFiles?.map((doc, i) => ({
      id: doc.id,
      name: doc.name,
      accepted: requiredDocs?.[i]?.accepted || true,
      isRequiredForPartialSign: doc.isRequiredForPartialSign,
    }));
    setRequiredDocs(newRequiredDocs || []);
  }, [clientData]);

  useEffect(() => {
    const isRequiredDocsAccepted = checkRequiredDocsAccepted();
    const isAllDocsAccepted = checkAllDocsAccepted();

    if (isRequiredDocsAccepted) {
      setShowAcceptDocsError(false);
    }
    if (isAllDocsAccepted) {
      setAllDocsChecked(true);
    } else {
      setAllDocsChecked(false);
    }
  }, [requiredDocs]);

  const handleNextClick = async () => {
    if (documentsReviewed) {
      const acceptedFileIds = requiredDocs
        .filter((d) => d.accepted)
        .map((d) => d.id);

      const requestSigned = await signRequest(
        confirmationCode,
        acceptedFileIds,
      );

      if (requestSigned) {
        onStepComplete();
      }
    } else {
      if (!checkRequiredDocsAccepted()) {
        markUncheckedRequiredDocs();
        setShowAcceptDocsError(true);
      } else {
        setDocumentsReviewed(true);
      }
    }
  };

  const handlePrevClick = () => {
    if (documentsReviewed) setDocumentsReviewed(false);
    else onStepBack();
  };

  const checkRequiredDocsAccepted = () => {
    if (!requiredDocs?.length) return true;
    for (let doc of requiredDocs) {
      if (doc.isRequiredForPartialSign && !doc.accepted) return false;
    }

    return true;
  };

  const checkAllDocsAccepted = () => {
    if (!requiredDocs?.length) return true;

    return requiredDocs?.every((d) => d.accepted);
  };

  const handleCheckAllCheckboxChange = ({ value }) => {
    setInternalStepState({
      ...internalStepState,
      requiredDocs: requiredDocs.map((d) => {
        if (!d.isRequiredForPartialSign) {
          d.accepted = value;
          d.hasError = value ? false : d.hasError;
        }
        return d;
      }),
    });
  };

  const handleDocCheckboxChange = ({ name, value }) => {
    let docs = [...requiredDocs];
    docs[parseInt(name)].accepted = value;
    if (value) {
      docs[parseInt(name)].hasError = false;
    }
    setRequiredDocs(docs);
  };

  const markUncheckedRequiredDocs = () => {
    setRequiredDocs(
      requiredDocs.map((d) => {
        if (d.isRequiredForPartialSign && !d.accepted) {
          d.hasError = true;
        }
        return d;
      }),
    );
  };

  return (
    <div>
      <StepContentHeader>Подписание документов</StepContentHeader>
      {!dataLoading &&
        (!documentsReviewed || (documentsReviewed && mainPhoneNumber)) && (
          <DocumentSigningHint
            requiredDocs={requiredDocs}
            style={{ marginBottom: '20px' }}
          />
        )}
      {!documentsReviewed ? (
        <>
          {dataLoading ? (
            <>
              <LoadingIndicator isFiles />
              <div className={styles.docsLoadingText}>
                Ваши документы заполняются. Пожалуйста, подождите...
              </div>
            </>
          ) : (
            <>
              <div className={styles.documentList}>
                {clientData?.documentFiles?.length && requiredDocs?.length
                  ? clientData?.documentFiles?.map((doc, index) => (
                      <DocumentFileItem
                        fileName={doc.name}
                        filePath={doc.path}
                        disabled={doc.isRequiredForPartialSign}
                        accepted={requiredDocs[index]?.accepted}
                        onCheckboxChange={handleDocCheckboxChange}
                        docIndex={index}
                        hasError={requiredDocs[index]?.hasError}
                        key={index}
                      />
                    ))
                  : ''}
              </div>
              <Checkbox
                label="Выбрать все документы"
                name="checkAllDocs"
                value={allDocsChecked}
                onChangeCallback={handleCheckAllCheckboxChange}
                style={{ marginTop: '12px', marginLeft: '23px' }}
              />
              <div
                className={`${styles.error} ${!showAcceptDocsError ? styles.hidden : ''}`}
              >
                Не выбраны обязательные документы
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <ConfirmationCodeForm
            codeLength={codeLength}
            phone={mainPhoneNumber}
            isDisabled={!checkRequiredDocsAccepted() || dataLoading}
          />
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={
            (documentsReviewed && confirmationCode?.length < codeLength) ||
            dataLoading
          }
          filled={false}
          style={{ margin: '12px auto 20px auto' }}
          onClick={handleNextClick}
        >
          Подписать документы
        </Button>

        <LinkButton
          onClick={handlePrevClick}
          style={{ marginBottom: '20px' }}
          disabled={dataLoading}
        >
          ← Вернуться назад
        </LinkButton>
      </div>
    </div>
  );
};

export default DocumentsStep;
