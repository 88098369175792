import { stripPhoneFormatting } from './format';

export const populateUserInputData = (requestData) => {
  return {
    middleNameRu: requestData?.interbankData?.middleNameRu,
    sex: requestData?.interbankData?.sex,
    idDocumentExpireDate: requestData?.interbankData?.idDocumentExpireDate,

    livingAddressRegion: requestData?.interbankData?.livingAddressRegion,
    livingAddressDistrict: requestData?.interbankData?.livingAddressDistrict,
    livingAddressUnitaryArea:
      requestData?.interbankData?.livingAddressUnitaryArea,
    livingAddressLocalityType:
      requestData?.interbankData?.livingAddressLocalityType,
    livingAddressLocality: requestData?.interbankData?.livingAddressLocality,
    livingAddressStreetType:
      requestData?.interbankData?.livingAddressStreetType,
    livingAddressStreet: requestData?.interbankData?.livingAddressStreet,
    livingAddressHouse: requestData?.interbankData?.livingAddressHouse,
    livingAddressBuilding: requestData?.interbankData?.livingAddressBuilding,
    livingAddressApartment: requestData?.interbankData?.livingAddressApartment,
    livingAddressPostalCode:
      requestData?.interbankData?.livingAddressPostalCode,

    maritalStatus: requestData?.maritalStatus,
    livingConditions: requestData?.livingConditions,

    incomeMainType: requestData?.incomeMainType,
    incomeMainAmount: requestData?.incomeMainAmount,
    jobPlace: requestData?.jobPlace,

    additionalPhoneNumber: requestData?.additionalPhoneNumber,
  };
};

export const prepareUserInputData = (userInputData) => {
  const dataCopy = { ...userInputData };

  if (dataCopy.additionalPhoneNumber) {
    dataCopy.additionalPhoneNumber = stripPhoneFormatting(
      dataCopy.additionalPhoneNumber,
    );
  }

  return dataCopy;
};
