import styles from './StepStatus.module.css';
const StepStatus = ({ steps, currentStepIndex }) => {
  return (
    <div className={styles.steps}>
      {steps.map((step, index) => {
        const stateStyle =
          index < currentStepIndex
            ? styles.completed
            : index > currentStepIndex
              ? styles.awaiting
              : styles.current;
        return (
          <div key={index} className={`${styles.step} ${stateStyle}`}>
            <div className={styles.stepName}>{step}</div>
            <div className={styles.outerBar}>
              <div className={styles.innerBar}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepStatus;
