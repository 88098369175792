import { useContext, useEffect, useState } from 'react';
import styles from './ConfirmationCodeForm.module.css';
import SegmentedInput from './SegmentedInput';

import { ApiContext } from '../context/api';
import { InternalStepStateContext } from '../context/internal-step-state-context';
import { formatPhone } from '../utils/format';
import LinkButton from './LinkButton';
import MissingPhoneHint from './MissingPhoneHint';

const ConfirmationCodeForm = ({
  phone,
  codeLength = 4,
  isDisabled = false,
  ref,
}) => {
  const { internalStepState, setInternalStepState } = useContext(
    InternalStepStateContext,
  );
  const setConfirmationCode = (value) => {
    setInternalStepState({ ...internalStepState, confirmationCode: value });
  };
  const lastCodeSentAt = internalStepState.lastCodeSentAt;
  const setLastCodeSentAt = (value) => {
    setInternalStepState({ ...internalStepState, lastCodeSentAt: value });
  };
  const { requestVerificationCode, dataLoading } = useContext(ApiContext);
  const [timeoutTimeLeftSeconds, setTimeoutTimeLeftSeconds] = useState(0);
  const resendTimeoutSeconds = 60;
  const [focusInputIndicator] = useState(0);

  useEffect(() => {
    const secondsLeftToWait = lastAttemptTimeoutIsNotExpired();
    if (secondsLeftToWait) {
      setTimeoutTimeLeftSeconds(secondsLeftToWait);
    } else {
      setNewConfirmationCode();
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (timeoutTimeLeftSeconds) {
      setTimeout(() => {
        if (isMounted) {
          setTimeoutTimeLeftSeconds((prev) => prev - 1);
        }
      }, 1000);
    }
    return () => {
      isMounted = false;
    };
  }, [timeoutTimeLeftSeconds]);

  const setNewConfirmationCode = async () => {
    if (!dataLoading && !lastAttemptTimeoutIsNotExpired()) {
      if (phone) {
        await requestVerificationCode();
        setLastCodeSentAt(Date.now());
        setTimeoutTimeLeftSeconds(resendTimeoutSeconds);
      }
    }
  };

  const lastAttemptTimeoutIsNotExpired = () => {
    if (lastCodeSentAt) {
      const sentAt = lastCodeSentAt;
      const diff = Math.round((Date.now() - sentAt) / 1000);
      if (diff < resendTimeoutSeconds) {
        const timeLeft =
          diff < 0 ? resendTimeoutSeconds : resendTimeoutSeconds - diff;
        return timeLeft;
      }
    }
    return false;
  };

  return (
    <div className={styles.container} ref={ref}>
      {!phone ? (
        <MissingPhoneHint style={{ marginBottom: '20px' }} />
      ) : (
        <div className={styles.description}>
          На ваш номер телефона
          <br /> {formatPhone(phone)}
          <br /> {dataLoading ? 'будет' : ''} выслан смс-код подтверждения.
          <br /> Введите код
        </div>
      )}

      <div className={styles.controlsContainer}>
        <SegmentedInput
          length={codeLength}
          onChangeCallback={setConfirmationCode}
          excludePattern={/[^\d]/g}
          triggerFocusInputIndicator={focusInputIndicator}
        />
      </div>
      {!dataLoading && !timeoutTimeLeftSeconds ? (
        <LinkButton
          disabled={
            timeoutTimeLeftSeconds || dataLoading || !phone || isDisabled
          }
          onClick={setNewConfirmationCode}
        >
          Отправить код повторно
        </LinkButton>
      ) : (
        ''
      )}
      {dataLoading ? (
        <div className={styles.loadingHint}>Отправка кода...</div>
      ) : (
        ''
      )}
      {timeoutTimeLeftSeconds ? (
        <div className={styles.timeoutHint}>
          Отправить код повторно можно будет через {timeoutTimeLeftSeconds} сек.
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ConfirmationCodeForm;
