import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useEffect, useRef } from 'react';
import { ReactComponent as CrossIcon } from '../assets/images/cross-icon.svg';
import styles from './Popup.module.css';

const Popup = ({ children, shown, setShown, title }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (shown) {
      disableBodyScroll(document.getElementsByName('body'));
    } else {
      enableBodyScroll(document.getElementsByName('body'));
    }
  }, [shown]);

  return (
    <div className={`${styles.container} ${!shown ? styles.hidden : ''}`}>
      <div className={styles.window}>
        <div className={styles.topPanel}>
          <h3 className={styles.modalTitle}>{title}</h3>
          <div className={styles.closeButtonWrapper}>
            <button onClick={() => setShown(false)} aria-label="Закрыть">
              <CrossIcon />
            </button>
          </div>
        </div>
        <div className={styles.content} ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
