import { useEffect } from 'react';
import {
  formatDate,
  formatFloat,
  formatInt,
  formatPhone,
} from '../utils/format';
import { MAX_INPUT_LENGTH } from '../utils/inputs';
import styles from './Input.module.css';
import InputError from './InputError';

const Input = ({
  value,
  defaultValue,
  label,
  className,
  style,
  type = 'text',
  disabled,
  name,
  onChangeCallback,
  required,
  error,
  resetError,
  maxLength,
}) => {
  const typesMap = { phone: 'text', int: 'text', float: 'text', date: 'date' };
  const inputModesMap = {
    phone: 'tel',
    int: 'numeric',
    float: 'decimal',
    date: 'numeric',
  };

  const handleChange = (event) => {
    if (onChangeCallback && name) {
      onChangeCallback({ name: name, value: event.target.value });
    }
  };

  const handleInput = (event) => {
    const finalMaxLength =
      maxLength !== undefined ? maxLength : MAX_INPUT_LENGTH;

    if (event.target.value?.length > finalMaxLength) {
      event.target.value = value;
    }

    if (event.target.value !== null && event.target.type !== 'date') {
      event.target.value = formatValue(event.target.value);
    }
  };

  useEffect(() => {
    if (type === 'phone') {
      if (onChangeCallback && name) {
        onChangeCallback({ name: name, value: formatPhone(value) });
      }
    }
  }, []);

  useEffect(() => {
    if (resetError) {
      resetError();
    }
  }, [value]);

  const formatValue = (value) => {
    const formatters = {
      phone: formatPhone,
      int: formatInt,
      float: formatFloat,
      date: formatDate,
    };

    return formatters[type] ? formatters[type](value) : value;
  };

  return (
    <div
      className={`${styles.inputWrapper} ${disabled ? styles.isDisabled : ''} ${error ? styles.error : ''}`}
    >
      <span className={styles.label}>
        <span className={styles.labelText}>{label}</span>
        {required ? <span className={styles.requiredIndicator}>*</span> : ''}
      </span>

      {error && <InputError message={error} style={{ position: 'absolute' }} />}

      <input
        className={`${styles.input} ${className}`}
        defaultValue={defaultValue || ''}
        value={value}
        type={typesMap[type] || type}
        style={style}
        disabled={disabled}
        onInput={handleInput}
        onChange={handleChange}
        inputMode={inputModesMap[type] || 'text'}
      />
    </div>
  );
};

export default Input;
