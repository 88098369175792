import logo from '../assets/images/logo.png';
import styles from './AppHeader.module.css';
const AppHeader = () => {
  return (
    <div className={styles.container}>
      <img src={logo} alt="логотип Лайт Лизинг" />
    </div>
  );
};

export default AppHeader;
