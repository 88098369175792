import styles from './DocumentSigningHint.module.css';

const DocumentSigningHint = ({ style, requiredDocs }) => {
  const allRequiredDocsAreSigned = requiredDocs?.every((d) => d.accepted);

  return (
    <div
      className={`${styles.container} ${allRequiredDocsAreSigned ? styles.allRequiredSigned : ''}`}
      style={style}
    >
      <div>Уважаемый клиент!</div>
      {!allRequiredDocsAreSigned && (
        <>
          <div>Для оформления онлайн-заявки необходимо подписать согласия:</div>
          <div>
            {requiredDocs
              ?.filter((doc) => !doc.isRequiredForPartialSign)
              .map((doc) => (
                <div key={doc.id}>
                  {doc.accepted ? '✅' : '❌'} {doc.name}
                </div>
              ))}
          </div>
          <div>
            Если Вы продолжите без предоставления всех обязательных согласий, Вы
            сможете подписать их в офисе компании.
          </div>
        </>
      )}
      {allRequiredDocsAreSigned && (
        <div>
          Вы собираетесь подписать все необходимые для оформления онлайн-заявки
          документы.
        </div>
      )}
    </div>
  );
};

export default DocumentSigningHint;
