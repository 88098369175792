const PhoneFormats = {
  BY: { format: '+xxx (xx) xxx-xx-xx', countryCode: '+375' },
  UA: { format: '+xxx (xx) xxx-xx-xx', countryCode: '+380' },
  RU: { format: '+x (xxx) xxx-xx-xx', countryCode: '+7' },
};

const phoneNumberPlaceholderChar = 'x';

function detectFormat(phone) {
  if (!phone) return false;

  let foundFormat;

  for (let formatObj of Object.values(PhoneFormats)) {
    if (phone.indexOf(formatObj.countryCode) === 0) {
      if ((foundFormat?.countryCode.length || 0) < formatObj.countryCode.length)
        foundFormat = formatObj;
    }
  }

  return foundFormat?.format;
}

export function formatPhone(phone) {
  let format = detectFormat(phone);

  if (!format) {
    format = '+xxx (xx) xxx-xx-xx';
  }

  const phoneSymbols = stripPhoneFormatting(phone)?.split('');
  return applyFormat(phoneSymbols, format);
}

export function stripPhoneFormatting(phone) {
  return phone?.replace(/(?!^\+)\D/g, '');
}

export function formatInt(str) {
  return str.replace(/[^\d]/g, '');
}

export function formatFloat(str) {
  if (str !== null) {
    str = str.replace(/[^\d.,]/g, '');
    str = str.replace(/,/g, '.');
    const fPointIndex = str.indexOf('.');
    if (fPointIndex !== -1) {
      const intPart = str.slice(0, fPointIndex);
      let floatPart = str.slice(fPointIndex + 1, fPointIndex + 3);
      floatPart = floatPart.replace('.', '');
      str = `${intPart}.${floatPart}`;
    }
  }
  return str;
}

export function formatDate(str) {
  const format = 'xx.xx.xxxx';
  str = str?.replace(/[^0-9]/g, '').split('');
  return applyFormat(str, format);
}

const applyFormat = (str, format) => {
  let result = '';
  let i = 0;

  while (i < format.length) {
    if (!str.length) break;

    result +=
      format[i] === phoneNumberPlaceholderChar || format[i] === str?.[i]
        ? str?.shift()
        : format[i];
    i++;
  }

  return result;
};
