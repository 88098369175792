import Lottie from 'lottie-react';
import FilesAnim from '../assets/animations/files.json';
import SpinnerAnim from '../assets/animations/loading-spinner.json';
const LoadingIndicator = ({ style, isFiles }) => {
  return (
    <div style={style}>
      {isFiles ? (
        <Lottie
          animationData={FilesAnim}
          loop={true}
          style={{ width: '100%', height: 'auto' }}
        />
      ) : (
        <Lottie
          animationData={SpinnerAnim}
          loop={true}
          style={{ width: '100%', height: 'auto' }}
        />
      )}
    </div>
  );
};

export default LoadingIndicator;
