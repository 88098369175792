import axios from 'axios';
import { useRef, useState } from 'react';
import Button from '../Button';

const CreateApplicationPage = () => {
  const apiRoot = useRef(process.env.REACT_APP_API_ROOT + '/ext/applications');
  const [applicationLink, setApplicationLink] = useState('');

  const createApplication = async () => {
    const resp = await axios.post(
      apiRoot.current,
      {
        externalId: crypto.randomUUID(),
        phoneNumber: '+375441111111',
      },
      {
        params: {
          apiKey:
            'b2f044df026e45eedb34f23b9ca514b41bade5a8c16be96d5d8a4a409f65de18',
        },
      },
    );

    const link = resp?.data?.response?.data?.url;

    setApplicationLink(link);
  };

  return (
    <div>
      <Button
        filled={true}
        onClick={createApplication}
        style={{ marginBottom: '20px' }}
      >
        Создать заявку
      </Button>

      {applicationLink && (
        <a target="_blank" href={applicationLink} rel="noreferrer">
          {applicationLink}
        </a>
      )}
    </div>
  );
};

export default CreateApplicationPage;
