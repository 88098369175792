import { ReactComponent as CheckMark } from '../assets/images/check.svg';
import { ReactComponent as Warning } from '../assets/images/error-icon.svg';
import styles from './SignedDocsStatus.module.css';

const SignedDocsStatus = ({ allDocsSigned, style }) => {
  return (
    <div
      style={style}
      className={`${styles.container} ${allDocsSigned ? styles.allSigned : styles.partiallySigned}`}
    >
      {allDocsSigned ? (
        <CheckMark className={styles.icon} />
      ) : (
        <Warning className={styles.icon} />
      )}
      {allDocsSigned
        ? 'Подписаны все необходимые документы'
        : 'Подписана часть документов'}
    </div>
  );
};

export default SignedDocsStatus;
