import { useContext } from 'react';
import { ClientDataContext } from '../context/client-data';
import Button from './Button';
import DocumentsReview from './DocumentsReview';
import SignedDocsStatus from './SignedDocsStatus';
import styles from './SuccessBlock.module.css';

const SuccessBlock = ({ onReset }) => {
  const { clientData } = useContext(ClientDataContext);
  const areAllDocsSigned = clientData?.documentFiles?.every(
    (doc) => doc.isSigned,
  );

  return (
    <div className={styles.container}>
      {/*<Lottie animationData={PopAnim} loop={false} style={{position:'absolute', height: '100vh'}}/>*/}
      <h3 className={styles.title}>Спасибо!</h3>

      <SignedDocsStatus
        allDocsSigned={areAllDocsSigned}
        style={{ marginTop: '20px' }}
      />

      {areAllDocsSigned ? (
        <p className={styles.text} style={{ textAlign: 'center' }}>
          Ваша заявка направлена менеджеру. Как только Ваши документы будут
          рассмотрены, мы сразу свяжемся с Вами
        </p>
      ) : (
        <p className={styles.text} style={{ textAlign: 'center' }}>
          Ваша заявка направлена менеджеру. Как только заявка будет рассмотрена,
          мы сразу свяжемся с Вами для подписания оставшейся части документов
        </p>
      )}

      <DocumentsReview />

      <div className={styles.buttons}>
        <a href="https://lls.by" style={{ textDecoration: 'none' }}>
          <Button onClick={onReset} filled={false} style={{ margin: 'auto' }}>
            Сайт Лайт Лизинг
          </Button>
        </a>
      </div>
    </div>
  );
};

export default SuccessBlock;
