import styles from './ClientNameConcurrencyHint.module.css';
const MissingPhoneHint = ({ style }) => {
  return (
    <div className={styles.container} style={style}>
      <div>Уважаемый клиент!</div>
      <div>
        Отсутствуют как номер телефона для СМС-верификации, так и номер телефона
        из деталей аутентификации текущей сессии.
      </div>
      <div>
        <b>Авторизуйтесь в МСИ при помощи СМС-кода.</b>
      </div>
      <div>Для формирования новой заявки обратитесь в Лайт Лизинг.</div>
    </div>
  );
};

export default MissingPhoneHint;
