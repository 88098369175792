export default [
  'Работа по договору /контракту/договору подряда',
  'МВД/МЧС',
  'Пенсия',
  'Декретный отпуск',
  'Индивидуальный предприниматель',
  'Самозанятый',
  'Работа за границей',
  'Гос. служащий',
  'Вооруженные силы',
];
