import Button from './Button';
import styles from './ErrorPopup.module.css';
import Popup from './Popup';

const ErrorPopup = ({ errorText, triggerClose, showContactButton }) => {
  return (
    <Popup shown={!!errorText} setShown={triggerClose} title={'Ошибка'}>
      <div className={styles.message}>{errorText}</div>
      {showContactButton ? (
        <a
          href="https://lls.by/?modal=/local/include/forms/callback.php"
          style={{
            textDecoration: 'none',
            marginBottom: '20px',
            display: 'block',
            width: '100%',
          }}
        >
          <Button
            filled={true}
            style={{ margin: 'auto', width: '100%', justifyContent: 'center' }}
          >
            Связаться
          </Button>
        </a>
      ) : (
        ''
      )}
      <Button
        filled={false}
        onClick={triggerClose}
        className={styles.actionButton}
      >
        Понятно
      </Button>
    </Popup>
  );
};

export default ErrorPopup;
