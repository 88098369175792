import styles from './Tooltip.module.css';

const Tooltip = ({ message, style, triangleWrapperStyle, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`} style={style}>
      <div className={styles.content}>{message}</div>
      <div className={styles.triangleWrapper} style={triangleWrapperStyle}>
        <div className={styles.triangle} />
      </div>
    </div>
  );
};

export default Tooltip;
