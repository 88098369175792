import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientDataContext } from '../../../context/client-data';
import { UserInputDataContext } from '../../../context/user-input-data';
import gender from '../../../types/gender';
import { personalDataStepInputs, validateInputs } from '../../../utils/inputs';
import Button from '../../Button';
import Input from '../../Input';
import LinkButton from '../../LinkButton';
import RadioInput from '../../RadioInput';
import ValidationPopup from '../../ValidationPopup';
import StepContentHeader from './../StepContentHeader';
import styles from './PersonalDataReview.module.css';

const PersonalDataReview = ({ onStepComplete }) => {
  const navigate = useNavigate();
  const { userInputData, setUserInputData } = useContext(UserInputDataContext);
  const genderOptions = Object.keys(gender).map((key) => {
    return { name: gender[key], value: key };
  });
  const { clientData } = useContext(ClientDataContext);
  const [failedValidationInputs, setFailedValidationInputs] = useState(null);
  const [validationPopupShown, setValidationPopupShown] = useState(false);

  const handleInputChange = ({ name, value }) => {
    if (failedValidationInputs?.[name]) {
      delete failedValidationInputs[name];
    }

    setUserInputData((prev) => {
      let updatedData = { ...prev };
      updatedData[name] = value;
      return updatedData;
    });
  };

  const handleNextClick = () => {
    const validationResult = validateInputs(
      userInputData,
      personalDataStepInputs,
    );
    if (validationResult.status) {
      onStepComplete();
    } else {
      setFailedValidationInputs(validationResult.failedInputs);
      setValidationPopupShown(true);
    }
  };

  return (
    <>
      <StepContentHeader>
        Личные данные и документ, удостоверяющий личность
      </StepContentHeader>
      <ValidationPopup
        failedInputs={failedValidationInputs}
        shown={validationPopupShown}
        triggerClose={() => setValidationPopupShown(false)}
      ></ValidationPopup>

      <div className={styles.container}>
        <Input
          value={clientData?.interbankData?.familyNameRu}
          label={'Фамилия'}
          disabled
          required
        />
        <Input
          value={clientData?.interbankData?.givenNameRu}
          label={'Имя'}
          disabled
          required
        />
        <Input
          value={clientData?.interbankData?.middleNameRu}
          label={'Отчество'}
          disabled={!!clientData?.interbankData?.middleNameRu}
        />
        <RadioInput
          options={genderOptions}
          value={clientData?.interbankData?.sex}
          label={'Пол'}
          disabled={!!clientData?.interbankData?.sex}
          required
        />
        <Input
          value={clientData?.interbankData?.birthdate}
          label={'Дата рождения'}
          type="date"
          disabled
          required
        />
        <Input
          value={`${clientData?.interbankData?.idDocumentSeries} ${clientData?.interbankData?.idDocumentNumber}`}
          label={'Серия и номер паспорта'}
          disabled
          required
        />
        <Input
          value={clientData?.interbankData?.nationalIdNumber}
          label={'Идентификационный номер'}
          disabled
          required
        />
        <Input
          value={clientData?.interbankData?.idDocumentIssueDate}
          type="date"
          label={'Дата выдачи'}
          disabled
          required
        />
        <Input
          defaultValue={clientData?.interbankData?.idDocumentExpireDate}
          label={'Срок действия'}
          type="date"
          name={'idDocumentExpireDate'}
          onChangeCallback={handleInputChange}
          // error={failedValidationInputs?.['idDocumentExpireDate']?.message}
          disabled={!!clientData?.interbankData?.idDocumentExpireDate}
          required
        />
        <Input
          value={clientData?.interbankData?.idDocumentAuthority}
          label={'Орган, выдавший документ'}
          disabled
          required
        />
        <Input
          value={clientData?.interbankData?.citizenship}
          label={'Гражданство'}
          disabled
          required
        />
      </div>
      <div className={styles.buttonsContainer}>
        <LinkButton
          onClick={() => navigate('wrong-data')}
          style={{ marginTop: '40px' }}
        >
          Данные неверны
        </LinkButton>
        <Button
          filled={false}
          style={{ margin: '20px auto 40px auto' }}
          onClick={handleNextClick}
        >
          Подтвердить данные
        </Button>
      </div>
    </>
  );
};

export default PersonalDataReview;
