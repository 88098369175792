import Lottie from 'lottie-react';
import { Link, useParams } from 'react-router-dom';
import ErrorAnim from '../../assets/animations/dog-error';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-call-icon.svg';
import Button from '../Button';
import styles from './WrondDataClaim.module.css';

const WrongDataClaimPage = () => {
  const { requestId } = useParams();

  return (
    <div className={styles.wrapper}>
      <Lottie
        animationData={ErrorAnim}
        loop={true}
        style={{ height: '30vh' }}
      />
      <div className={styles.text}>
        <p>
          Внесение изменений в паспортные данные и адрес регистрации возможно
          только при обращении в обслуживающий банк.
        </p>
        <p>
          Вы можете связаться с нами и оформить заявку с помощью персонального
          менеджера
        </p>
      </div>
      <a href="tel:+375445706060" className={styles.link}>
        <Button className={styles.callButton} filled={true}>
          <PhoneIcon />
          +375 (44) 570 60 60
        </Button>
      </a>
      <Link className={styles.link} to={`/${requestId}`}>
        <Button filled={false}>На страницу заявки</Button>
      </Link>
    </div>
  );
};

export default WrongDataClaimPage;
