import { ReactComponent as DownloadIcon } from '../../../assets/images/download-icon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/images/eye-icon.svg';
import Checkbox from '../../Checkbox';
import styles from './DocumentFileItem.module.css';

const DocumentFileItem = ({
  fileName,
  filePath,
  onCheckboxChange,
  docIndex,
  accepted,
  hasError,
  disabled,
  actionDownload = false,
  useApiRootPrefix = true,
}) => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const finalPath = useApiRootPrefix
    ? `${apiRoot}/${filePath}`.replace(/([^:]\/)\/+/g, '$1')
    : filePath;

  return (
    <div className={`${styles.container} ${hasError ? styles.error : ''}`}>
      <Checkbox
        label={fileName}
        style={{ padding: '16px', paddingLeft: '22px' }}
        onChangeCallback={!disabled ? onCheckboxChange : () => {}}
        value={accepted}
        name={docIndex}
        disabled={disabled}
      />
      <a
        className={styles.viewButton}
        href={finalPath}
        target="_blank"
        download={actionDownload}
        rel="noreferrer"
      >
        {actionDownload ? <DownloadIcon /> : <EyeIcon />}
      </a>
    </div>
  );
};

export default DocumentFileItem;
